<template>
  <div>
    <el-dialog v-model="view" width="1000px" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="steps">
        <el-steps :active="3">
          <el-step title="项目发起申请" description="请填写项目发起的资料"></el-step>
          <el-step title="审核" description="审核。"></el-step>
          <el-step title="确认发起签字" description="发起签字"></el-step>
          <el-step title="等待签字完毕" description="等待协议中所有成员签字完毕"></el-step>
          <el-step title="完成" description="完成签字，项目成功发起"></el-step>
        </el-steps>
      </div>

      <div class="form">
        <el-form label-width="120px">
          <el-form-item label="项目标题">
            {{ form.Title }}
          </el-form-item>
          <el-form-item label="标语">
            {{ form.ActivityName }}
          </el-form-item>
          <el-form-item label="委员会数量">
            {{ form.CommitteeNumber }}
          </el-form-item>
          <el-form-item label="共同发起人">
          <span v-for="(item, index) in form.Personnel" :key="index">
                  {{ item.Name }}-{{ item.Mobile }}
          </span>
          </el-form-item>
          <el-form-item label="贡献值额度">
            {{ form.ToNumber }}
          </el-form-item>
          <el-form-item label="LOGO" class="changeImg">
            <img :src="form.Logo" class="avatar">
          </el-form-item>
          <el-form-item label="项目目标及简介">
            {{ form.Brief }}
          </el-form-item>
          <el-form-item label="补充协议">
            {{ form.EnclosureText }}
          </el-form-item>
          <el-form-item label="机构方" :required="true">
            <el-button class="Mech" @click="openMaech">请选择机构方</el-button>
            <p>机构方：{{ Mech.Name }}</p>
            <p>负责人：{{ Mech.UserName }}</p>
            <p>工商注册号/社会信用代码：{{ Mech.ThirdPartyUserId }}</p>
          </el-form-item>
        </el-form>
      </div>

      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="view = false">取 消</el-button>
                    <el-button type="primary" @click="launch">发起签署</el-button>
                </span>
      </template>
    </el-dialog>
    <SignMech ref="SignMech"></SignMech>
  </div>

</template>

<script>
import {setinitiate} from "../../../api/ProjectAdmin/Cpproval";
import SignMech from '../../../components/SignMech'

export default {
  data() {
    return {
      form: {},
      actionUrl: '',
      view: false,
      Mech: {}
    }
  },
  mounted() {

  },
  methods: {
    openMaech() {
      this.$refs.SignMech.open()
    },
    launch() {
      let App_id = this.form.App_id
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      let Mech = this.Mech
      if (Mech.Name && Mech.UserName && Mech.ThirdPartyUserId) {
        this.openLoading()
        setinitiate({App_id, Admin_id, Mech}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            this.view = false
            this.$parent.getData()
          } else {
            this.$message.error(res.message);
          }
        })
      } else {
        this.$message.error('请选择机构方');
      }

    },
    closeInput() {
      this.form = {}
      this.Mech.Name = ''
      this.Mech.UserName = ''
    },
    open(data) {
      this.closeInput()
      this.view = true
      this.form = data
    },
    openLoading() {
      this.loading = this.$loading({
        lock: true,//lock的修改符--默认是false
        text: '处理中',//显示在加载图标下方的加载文案
        spinner: 'el-icon-loading',//自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
      });
    },

    closeLoading() {
      this.loading.close();
    },
    handleSign(data) {
      this.Mech = data
    }
  },
  components: {
    SignMech
  }
}
</script>

<style scoped lang="scss">
.steps {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 45px;
}

.form {
  height: 400px;
  overflow-y: auto;
}

.avatar {
  width: 90px;
  height: 90px;
  display: block;
}

.Mech {
  margin-bottom: 20px;
}
</style>