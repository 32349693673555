<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 项目发起确认
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container" v-loading="loading">
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column label="logo">
          <template #default="scope">
            <el-avatar shape="square" :size="50" :src="scope.row.Logo"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="Title" label="项目标题" width="190"></el-table-column>
        <el-table-column prop="ActivityName" label="标语" width="250" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="ActivityName" label="委员会成员">
          <template #default="scope">
            <span v-for="(item,index) in scope.row.Personnel" :key="index" class="Name">
              {{ item.Name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="CommitteeNumber" label="委员会数量"></el-table-column>
        <el-table-column prop="ToNumber" label="项目总额度"></el-table-column>
        <el-table-column prop="Time" label="创建日期"></el-table-column>
        <el-table-column prop="StepsStart" label="状态"></el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-button size="small" type="text" @click="openflowId(scope.row.FlowId)"
                       v-if="scope.row.StepsStart=='签署已完成'">
              查看签署文档
            </el-button>
            <el-button type="text" @click="openfile(scope.row.FileId)" v-else>查看原文档</el-button>

          </template>
        </el-table-column>

        <el-table-column width="180" label="操作">
          <template #default="scope">
            <el-button
                class="green"
                type="text"
                v-if="scope.row.StepsStart=='未发起签署'||scope.row.StepsStart=='签署已终止'"
                @click="launch( scope.row)"
            >发起签署
            </el-button>
            <el-button
                type="text"
                class="red"
                v-if="scope.row.StepsStart=='未发起签署'||scope.row.StepsStart=='签署已终止'"
                @click="fail( scope.row.App_id)"
            >不通过
            </el-button>
            <el-button
                class="green"
                type="text"
                v-if="scope.row.StepsStart=='签署中'"
                @click="flows( scope.row.App_id)"
            >确认归档
            </el-button>
            <el-button
                class="red"
                type="text"
                v-if="scope.row.StepsStart=='签署中'"
                @click="revoke( scope.row.App_id)"
            >撤销签署
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <InitiateProjiect ref="InitiateProjiect"></InitiateProjiect>
  </div>
</template>

<script>
import InitiateProjiect from '../ProjectAdmin/com/InitiateProjiect'
import {openFileId, openFlowId} from "../../api/Contract";
import {fetchData, setfail, revokeSign, setflows} from "../../api/ProjectAdmin/Cpproval";


export default {
  data() {
    return {
      loading: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      tableData: [],
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    openflowId(FlowId) {
      this.openLoading()
      openFlowId({FlowId}).then(res => {
        this.closeLoading()
        if (res.code == 0) {
          window.open(res.fileUrl)
        } else {
          this.$message.error(res.message);
        }
      })
    },
    launch(data) {
      this.$refs.InitiateProjiect.open(data)
    },
    flows(App_id) {
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      this.openLoading('正在确认是否归档中')
      setflows({App_id, Admin_id}).then(res => {
        this.closeLoading()
        if (res.code == 0) {
          this.$message.success(res.message);
          this.getData()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    revoke(App_id) {
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      this.$confirm("是否确认撤销并终止该签署流程吗？", "提示", {
        type: "warning"
      }).then(() => {
        this.openLoading('处理中')
        revokeSign({App_id, Admin_id}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            this.getData()
          } else {
            this.$message.error(res.message);
          }
        })
      }).catch()

    },
    fail(App_id) {
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id

      this.$confirm("确定要执行不通过吗？", "提示", {
        type: "warning"
      }).then(() => {
        this.openLoading()
        setfail({Admin_id, App_id}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            this.getData()
          } else {
            this.$message.error(res.message);
          }
        });

      }).catch(() => {
      });
    },
    openfile(FileId) {
      openFileId({FileId}).then(res => {
        if (res.code == 0) {
          window.open(res.downloadUrl)
        } else {
          this.$message.error(res.message);
        }
      })
    },
    getData() {
     this.loading=true
      fetchData({
        skip: this.query.pageIndex
      }).then(res => {
        this.loading=false
        let bindata = res.sum;
        for (let i in bindata) {
          let Personnel = bindata[i].Personnel
          Personnel = JSON.parse(Personnel)
          bindata[i].Personnel = Personnel
        }
        let number = res.number
        this.query.pageTotal = number
        this.tableData = bindata;
      });
    },
    openLoading(data) {
      if (!data) {
        data = '加载中'
      }
      this.loading = this.$loading({
        lock: true,
        text: data,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },

    closeLoading() {
      this.loading.close();
    },
  },
  components: {
    InitiateProjiect
  }
}


</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.table {
  width: 100%;
  font-size: 14px;
}

.green {
  color: #00a854;
}

.red {
  color: red;
}
</style>
`