import request from '../../utils/request';

/**
 * 项目发起确认
 * @param query
 * @returns {AxiosPromise}
 */

//查询数据
export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Cpproval/find',
        method: 'get',
        params: query
    });
};

//不通过申请
export const setfail = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Cpproval/fail',
        method: 'post',
        data: query
    });
};

//发起签署
export const setinitiate = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Cpproval/initiate',
        method: 'post',
        data: query
    });
};

//终止签署流程
export const revokeSign = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Cpproval/revoke',
        method: 'post',
        data: query
    });
};

export const setflows = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'ProjectAdmin/Cpproval/flows',
        method: 'post',
        data: query
    });
};




